import React, { useEffect, useState } from 'react';
import { Layout, Menu, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import { useAuthStore } from '../../store/authUserStore';
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import './SidebarComponent.css';
import { useTranslation } from 'react-i18next';

const { Sider } = Layout;

const SidebarComponent: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [activeItem, setActiveItem] = useState<string>('sidebar.events');
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const navigate = useNavigate();
  const { token, currentUser, clearToken } = useAuthStore((state) => state);
  const { t } = useTranslation();

  const handleItemClick = (key: string) => {
    setActiveItem(key);
  };

  useEffect(() => {
    if (activeItem === 'logout') {
      setActiveItem('sidebar.events');
    }
  }, [activeItem])

  const handleOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  const handleLogout = () => {
    clearToken();
    navigate('/login');
  };

  const showSidebarDataByRole = (item: any) => {
    if (!item.roles) return true;
    if (!currentUser) return true;
    return item.roles.includes(currentUser!.role);
  }

  const menuItems = [
    ...SidebarData
      .filter((item) => showSidebarDataByRole(item) && (navigator.onLine ? true : item.showOnOffline))
      .map((item) => ({
        key: item.title,
        icon: item.icon,
        label: item.subNav ? t(item.title) : <Link to={item.path}>{t(item.title)}</Link>,
        children: item.subNav
          ? item.subNav
            .filter((item) => showSidebarDataByRole(item) && (navigator.onLine ? true : item.showOnOffline))
            .map((subItem) => ({
              key: subItem.title,
              icon: subItem.icon,
              label: <Link to={subItem.path}>{t(subItem.title)}</Link>,
            }))
          : undefined,
      }))
  ];

  if (navigator.onLine) {
    menuItems.push({
      key: 'logout',
      icon: <LogoutOutlined />,
      label: t('general.logout'),
      style: { marginTop: 'auto' },
      onClick: handleLogout,
    } as any);
  }

  return token ? (
    <Sider
      theme="light"
      className="sidebar"
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      width={collapsed ? 80 : 240}
      collapsedWidth={80}
      style={{ borderRight: '1px solid #e8e8e8', height: 'auto' }}
    >
      <div className="logo">
        <Button
          className="menu-toggle"
          type="text"
          icon={<MenuOutlined />}
          onClick={() => setCollapsed(!collapsed)}
        />
      </div>
      <Menu
        mode="inline"
        selectedKeys={[activeItem]}
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
        items={menuItems}
        onClick={(e) => handleItemClick(e.key)}
      />
    </Sider>
  ) : (
    <></>
  );
};

export default SidebarComponent;
