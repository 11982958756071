import axios, { AxiosError } from 'axios';
import { Product } from '../models/products/products';
import { Customer } from '../models/customers';
import { EventModel } from '../models/events/events';
import { NewOrderItem } from '../models/orderItems/newOrderItem';
import { NewOrder } from '../models/orders/newOrder';
import { Order } from '../models/orders/order';
import { NewStand } from '../models/stands/newStand';
import { Stand } from '../models/stands/stand';
import { User } from '../models/users';
import { StandType } from '../models/standTypes/StandType';
import { StandSector } from '../models/standSectors/standSector';
import { notifySuccess, notifyError } from '../components/NotificationProvider';
import { DataTableFilterProps } from '../components/DataTable/interfaces/dataTableFilterProps';
import { NewCountry } from '../models/countries/newCountry';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/';

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

instance.interceptors.response.use(
    response => response,
    async error => {
        if (error.response && error.response.status === 401 && navigator.onLine) {
            const refreshToken = localStorage.getItem('refresh_token');
            if (refreshToken) {
                try {
                    const refreshInstance = axios.create({
                        baseURL: API_URL,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    await refreshInstance.post('/auth/refresh', { token: refreshToken }).then((response) => {
                        localStorage.setItem('token', response.data.access_token);
                        error.config.headers['Authorization'] = `Bearer ${response.data.access_token}`;

                        return instance.request(error.config);
                    }).catch(() => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('refresh_token');
                        window.location.href = '/login';
                    });


                } catch (refreshError) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refresh_token');
                    window.location.href = '/login';
                }
            } else {
                window.location.href = '/login';
            }
        }
        return Promise.reject(error);
    }
);


export async function apiCall<T>(call: () => Promise<T>, successMessage: string, errorMessage: string, offlineCallback?: () => Promise<void>) {
    try {
        const result = await call();
        notifySuccess(successMessage);
        return result;
    } catch (error: any) {
        if (!navigator.onLine && error.code === AxiosError.ERR_NETWORK && offlineCallback) {
            await offlineCallback();
            notifySuccess(successMessage);
        } else {
            notifyError(errorMessage);
            throw error;
        }
    }
}

const responseBody = (response: any) => response.data;

const requests = {
    get: (url: string, config?: any) => instance.get(url, config).then(responseBody),
    getPaginated: (url: string, body: DataTableFilterProps = { filters: {}, page: 1, pageSize: 10 }) =>
        instance.get(url, { params: { filters: body.filters, ...body } }).then(responseBody),
    post: (url: string, body: {}) => instance.post(url, body).then(responseBody),
    put: (url: string, body: {}) => instance.put(url, body).then(responseBody),
    patch: (url: string, body: {}) => instance.patch(url, body).then(responseBody),
    delete: (url: string) => instance.delete(url).then(responseBody),
};

const Auth = {
    login: (username: string, password: string) => requests.post('/auth/login', { username, password }),
    register: (user: User) => requests.post('/auth/register', user),
    getCurrentUser: () => requests.get('/auth/me'),
    refresh: (token: string) => requests.post('/auth/refresh', { token }),
    forgotPassword: (email: string) => requests.post('/auth/forgot-password', { email }),
    resetPassword: (resetToken: string, newPassword: string) => requests.patch('/auth/reset-password', { resetToken, newPassword }),
};

const Users = {
    getAll: () => requests.get('/users'),
    getAllPaginated: (body: DataTableFilterProps) => requests.getPaginated('/users/paginated', body),
    getById: (id: string) => requests.get(`/users/${id}`),
    update: (id: string, user: { username?: string; email?: string; role?: string; }) => requests.patch(`/users/${id}`, user),
    delete: (id: string) => requests.delete(`/users/${id}`),
    create: (user: any) => requests.post('/users', user)
};

const Products = {
    getAll: (params: any = {}) => requests.get('/products', { params }),
    getAllPaginated: (body: DataTableFilterProps) => requests.getPaginated('/products/paginated', body),
    getById: (id: string) => requests.get(`/products/${id}`),
    create: (product: Product) => requests.post('/products', product),
    update: (id: string, product: Product) => requests.patch(`/products/${id}`, product),
    delete: (id: string) => requests.delete(`/products/${id}`),
    getByEvent: (eventId: string) => requests.get(`/events/${eventId}/products`),
    addToEvent: (eventId: string, productId: string) => requests.post(`/events/${eventId}/products/${productId}`, {}),
    removeFromEvent: (eventId: string, productId: string) => requests.delete(`/events/${eventId}/products/${productId}`),
    getEventsByProduct: (productId: string) => requests.get(`/products/${productId}/events`),
};

const Customers = {
    getAll: (params: any = {}) => requests.get('/customers', { params }),
    getAllPaginated: (body: DataTableFilterProps) => requests.getPaginated('/customers/paginated', body),
    getById: (id: string) => requests.get(`/customers/${id}`),
    getCustomerByUserId: (id: string) => requests.get(`/customers/by-user/${id}`),
    getByEvent: (eventId: string) => requests.get(`/events/${eventId}/customers`),
    addToEvent: (eventId: string, customerId: string) => requests.post(`/events/${eventId}/customers/${customerId}`, {}),
    removeFromEvent: (eventId: string, customerId: string) => requests.delete(`/events/${eventId}/customers/${customerId}`),
    create: (customer: Customer) => requests.post('/customers', customer),
    update: (id: string, customer: Customer) => requests.patch(`/customers/${id}`, customer),
    delete: (id: string) => requests.delete(`/customers/${id}`),
};

const Events = {
    getAll: (params: any = {}) => requests.get('/events', { params }),
    getAllByStatus: (status: string) => requests.get(`/events/by/status/${status}`),
    getAllPaginated: (body: DataTableFilterProps) => requests.getPaginated('/events/paginated', body),
    getById: (id: string) => requests.get(`/events/${id}`),
    create: (event: EventModel) => requests.post('/events', event),
    update: (id: string, event: EventModel) => requests.patch(`/events/${id}`, event),
    delete: (id: string) => requests.delete(`/events/${id}`),
    downloadInvoiceByCustomerId: (eventId: string, customerId: string, standType: string) => requests.get(`/events/${eventId}/stand-type/${standType}/customer/${customerId}/invoice`, { responseType: 'blob' }),
    downloadIssuedArticlesReportByCustomerId: (eventId: string, customerId: string, standType: string) => requests.get(`/events/${eventId}/stand-type/${standType}/customer/${customerId}/issued-articles-report`, { responseType: 'blob' }),
    downloadIssuedEventArticlesByCustomerReport: (eventId: string, standType: string) => requests.get(`/events/${eventId}/stand-type/${standType}/issued-articles-by-customer-report`, { responseType: 'blob' }),
    downloadIssuedEventArticlesByProductReport: (eventId: string, standType: string) => requests.get(`/events/${eventId}/stand-type/${standType}/issued-articles-by-product-report`, { responseType: 'blob' })
};

const Stands = {
    getAll: (params: any = {}) => requests.get('/stands', { params }),
    getAllByEventId: (eventId: string) => requests.get(`/stands/event/${eventId}`),
    getAllPaginated: (body: DataTableFilterProps) => requests.getPaginated('/stands/paginated', body),
    getById: (id: string) => requests.get(`/stands/${id}`),
    create: (stand: NewStand) => requests.post('/stands', stand),
    update: (id: string, stand: Stand) => requests.patch(`/stands/${id}`, stand),
    delete: (id: string) => requests.delete(`/stands/${id}`),
    getByCustomerId: (customerId: string) => requests.get(`/customers/${customerId}/stands`),
};

const Orders = {
    getAll: (params: any = {}) => requests.get('/orders', { params }),
    getAllByEventId: (eventId: string) => requests.get(`/orders/event/${eventId}`),
    getAllPaginated: (body: DataTableFilterProps) => requests.getPaginated('/orders/paginated', body),
    getById: (id: string) => requests.get(`/orders/${id}`),
    getByStandId: (standId: string) => requests.get(`/orders/by-stand/${standId}`),
    getByCustomerId: (customerId: string) => requests.get(`/orders/by-customer/${customerId}`),
    create: (order: NewOrder) => requests.post('/orders', order),
    update: (id: string, order: Order) => requests.patch(`/orders/${id}`, order),
    delete: (id: string) => requests.delete(`/orders/${id}`),
    downloadPDFByBillType: (id: string, billType: string) => requests.get(`/orders/${billType}/pdf/${id}`, { responseType: 'blob' })
};

const OrderItems = {
    getAll: (params: any = {}) => requests.get('/order-items', { params }),
    getAllByEventId: (eventId: string) => requests.get(`/order-items/event/${eventId}`),
    getAllPaginated: (body: DataTableFilterProps) => requests.getPaginated('/order-items/paginated', body),
    getById: (id: string) => requests.get(`/order-items/${id}`),
    create: (orderItem: NewOrderItem) => requests.post('/order-items', orderItem),
    update: (id: string, orderItem: NewOrderItem) => requests.patch(`/order-items/${id}`, orderItem),
    delete: (id: string) => requests.delete(`/order-items/${id}`),
};

const ProductTypes = {
    getAll: (params: any = {}) => requests.get('/product-types', { params }),
    getAllPaginated: (body: DataTableFilterProps) => requests.getPaginated('/product-types/paginated', body),
    getById: (id: string) => requests.get(`/product-types/${id}`),
    create: (productType: { name: string; notes?: string }) => requests.post('/product-types', productType),
    update: (id: string, productType: { name?: string; notes?: string }) => requests.patch(`/product-types/${id}`, productType),
    delete: (id: string) => requests.delete(`/product-types/${id}`),
};

const StandTypes = {
    getAll: (params: any = {}) => requests.get('/stand-types', { params }),
    getAllPaginated: (body: DataTableFilterProps) => requests.getPaginated('/stand-types/paginated', body),
    getById: (id: string) => requests.get(`/stand-types/${id}`),
    create: (standType: StandType) => requests.post('/stand-types', standType),
    update: (id: string, standType: StandType) => requests.patch(`/stand-types/${id}`, standType),
    delete: (id: string) => requests.delete(`/stand-types/${id}`),
};

const StandSectors = {
    getAll: (eventId: string) => requests.get(`/events/${eventId}/stand-sectors`),
    getAllByEventPaginated: (eventId: string, body: DataTableFilterProps) => requests.getPaginated(`/events/${eventId}/stand-sectors/paginated`, body),
    getById: (id: string) => requests.get(`/stand-sectors/${id}`),
    create: (standSector: StandSector) => requests.post('/stand-sectors', standSector),
    update: (id: string, standSector: StandSector) => requests.patch(`/stand-sectors/${id}`, standSector),
    delete: (id: string) => requests.delete(`/stand-sectors/${id}`),
};

const Countries = {
    getAll: (params: any = {}) => requests.get('/countries', { params }),
    getAllPaginated: (body: DataTableFilterProps) => requests.getPaginated('/countries/paginated', body),
    getById: (id: string) => requests.get(`/countries/${id}`),
    create: (item: NewCountry) => requests.post('/countries', item),
    update: (id: string, item: NewCountry) => requests.patch(`/countries/${id}`, item),
    delete: (id: string) => requests.delete(`/countries/${id}`),
};

export interface ListData<T> {
    items: T[];
    totalRecords: number;
    currentPage: number;
    pageSize: number;
    orderBy: string;
    total: number;
}

export default {
    Auth,
    Users,
    Products,
    Customers,
    Events,
    Stands,
    Orders,
    OrderItems,
    ProductTypes,
    StandTypes,
    StandSectors,
    Countries
};
